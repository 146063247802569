<template>
  <v-container class="py-0">
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 py-0"
      >
        <v-row>
          <v-col cols="1" class="py-0 pt-5">
            <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
          </v-col>
          <v-col cols="11" class="pb-0">
            <PageHeader :title="title" class="py-0" />
          </v-col>
        </v-row>
        <v-card>
          <v-data-table
            :headers="formatosHeader"
            class="elevation-1"
            :items="formatosEmailCotizador"
            :search="search"
            item-key="emailId"
          >
            <template v-slot:[`item.activo`]="{ item }">
              <v-icon v-if="item.activo" small color="primary">
                {{ check }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn color="primary" @click="newFormato" class="to-right">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="editFormato(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar formato</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openDeleteFormat(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar formato</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>

    <DeleteDialog
      :titleProp="titleDelete"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteFormat()"
    />
  </v-container>
</template>
  
  <script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "FormatoEmailLotesDeudas",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda,
  },
  data: () => ({
    title: enums.titles.FORMATOS_EMAIL_LOTES_DEUDAS,
    optionCode: enums.webSiteOptions.FORMATO_EMAIL_LOTES_DEUDAS,
    check: enums.icons.CHECK_OUTLINE,
    searchIcon: enums.icons.SEARCH,
    openDeleteDialog: false,
    routeToGo: "ConfiguracionLotesDeudas",
    formatosEmailCotizador: [],
    search: "",
    codigo: "LOTE",
    formatoToDelete: {},
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    loadingDeleteBtn: false,
    titleDelete: "¿Eliminar formato?",
    formatosHeader: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
        sortable: false,
      },
      { text: "Email remitente", value: "emailRemitente", sortable: false },
      { text: "Servidor", value: "esName", sortable: false },
      { text: "Entidad facturante", value: "entFac", sortable: false },
      { text: "Activo", value: "activo", align: "center", sortable: false },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    allowedActions: null,
    canEdit: true,
    canDelete: true,
    canCreate: true,
    showExpand: false,
    showHelp: false,
    showIcon: true,
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.loadFormatos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchFormatosEmail: "solicitudesAfiliacion/fetchFormatosEmail",
      deleteFormatoEmail: "solicitudesAfiliacion/deleteFormatoEmail",
    }),
    setPermisos() {
      // this.allowedActions?.forEach(x => {
      //   switch (x.optionCode) {
      //     case enums.modules.adminSistema.permissions
      //       .NUEVO_FORMATO_EMAIL_:
      //       this.canCreate = true;
      //       break;
      //     case enums.modules.adminSistema.permissions
      //       .EDITAR_FORMATO_EMAIL_:
      //       this.canEdit = true;
      //       break;
      //     case enums.modules.adminSistema.permissions
      //       .ELIMINAR_FORMATO_EMAIL_
      //       this.canDelete = true;
      //       break;
      //     default:
      //       break;
      //   }
      // });
    },
    async loadFormatos() {
      const response = await this.fetchFormatosEmail(this.codigo);
      this.formatosEmailCotizador = response;
    },
    editFormato(item) {
      this.$router.push({
        name: "EditFormatoEmailLoteDeuda",
        params: { formato: item },
      });
    },
    openDeleteFormat(item) {
      this.formatoToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteFormat() {
      this.loadingDeleteBtn = true;
      try {
        const res = await this.deleteFormatoEmail(this.formatoToDelete.emailId);
        if (res.status === 200) {
          this.openDeleteDialog = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadFormatos();
          this.loadingDeleteBtn = false;
        }
      } catch (error) {
        this.openDeleteDialog = false;
        this.loadingDeleteBtn = false;
      }
      this.formatoToDelete = {};
    },
    newFormato() {
      this.$router.push({ name: "EditFormatoEmailLoteDeuda" });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.v-toolbar__content {
  height: 40px !important;
}
</style>
  